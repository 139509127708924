import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
// import { Main } from 'styles/competitor-template';
// import { AppSumoTestimonials } from '../styles/privilages';
import {
  TestimonialsContent,
  TestimonialsContentGoogle,
} from '../utils/contentProvider';
import { signUp } from '../utils/contentProvider/function';
import LoginWithGoogle from '../components/LoginWithGoogle';
import NewFaq from '../components/NewFaq/NewFaq';
import TrustedBy from '../components/TrustedBy';
import 'styles/competitor-template.scss';
import 'styles/privilages.scss';

const Competitor = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Looking for the best alternative to Markup.io? - Use Ruttl! ✨"
          url="https://ruttl.com/markup-io-alternative/"
          description="Looking for the best alternative to Markup.io? Try Ruttl- the fastest website feedback tool to review developed websites!"
        />
        <main className="competitor-styled-main">
          <section
            className="competitor-hero"
            style={{ background: '#fff', paddingBottom: 0 }}>
            <div className="container">
              <h1 className="center">Why choose ruttl over Markup.io?</h1>
              <p className="center">
                ruttl is advantageous as compared to MarkUp. Upgrade from plain
                commenting to live editing, and collaborate with your team in a
                better manner.
              </p>
              <div
                className="reveal-1 button-container"
                style={{ marginBottom: 30 }}>
                <LoginWithGoogle />

                <div>
                  <Link
                    className="button button-white"
                    to="/pricing/"
                    style={{ padding: '16px 20px' }}>
                    View pricing
                    <svg width="20" height="20" viewBox="0 0 512 512">
                      <path
                        fill="none"
                        stroke="#160647"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="48"
                        d="M268 112l144 144-144 144M392 256H100"></path>
                    </svg>
                  </Link>
                </div>
              </div>
              <TrustedBy />
            </div>
          </section>
          <div className="container">
            <div className="competetior-table-main">
              <h2 className="center">Competitive Landscape</h2>
              <div
                className="competitor-feature-block flex"
                style={{ margin: '40px 0 0 0', maxWidth: '100%' }}>
                <div className="feature-list" style={{ width: '50%' }}>
                  <div className="feature-head flex align-items-center justify-content-center">
                    <h2>Key Features</h2>
                  </div>
                  {[
                    {
                      id: 0,
                      featuretitle: 'Review websites',
                    },
                    {
                      id: 1,
                      featuretitle: 'Textual comments',
                    },
                    {
                      id: 2,
                      featuretitle: 'Video comments',
                    },
                    {
                      id: 3,
                      featuretitle: 'Edit Content',
                    },
                    {
                      id: 4,
                      featuretitle: 'Replace Assets',
                    },
                    {
                      id: 5,
                      featuretitle: 'Real Time changes',
                    },
                  ].map(({ featuretitle, id }) => (
                    <div
                      key={id}
                      className="feature-block flex align-items-center justify-content-center">
                      <h3>{featuretitle}</h3>
                    </div>
                  ))}
                </div>
                <div className="feature-availability flex">
                  <div className="single-feature-row" style={{ width: '50%' }}>
                    <div className="feature-head flex align-items-center justify-content-center">
                      <img
                        src="/assets/img/ruttl-logo.svg"
                        alt="ruttl-logo"
                        title="ruttl-logo"
                      />
                    </div>
                    {[
                      {
                        id: 0,
                        featurecheck: 'check',
                      },
                      {
                        id: 1,
                        featurecheck: 'check',
                      },
                      {
                        id: 2,
                        featurecheck: 'check',
                      },
                      {
                        id: 3,
                        featurecheck: 'check',
                      },
                      {
                        id: 4,
                        featurecheck: 'check',
                      },
                      {
                        id: 5,
                        featurecheck: 'check',
                      },
                    ].map(({ featurecheck, id }) => (
                      <div
                        key={id}
                        className="feature-block flex align-items-center justify-content-center">
                        <span
                          className={`flex justify-content-center align-items-center ${featurecheck}`}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="single-feature-row" style={{ width: '50%' }}>
                    <div className="feature-head flex align-items-center justify-content-center">
                      <img
                        src="/assets/img/markup.svg"
                        alt="markup logo"
                        title="markup logo"
                      />
                    </div>
                    {[
                      {
                        id: 0,
                        featurecheck: 'check',
                      },
                      {
                        id: 1,
                        featurecheck: 'check',
                      },
                      {
                        id: 2,
                        featurecheck: 'check cross',
                      },
                      {
                        id: 3,
                        featurecheck: 'check cross',
                      },
                      {
                        id: 4,
                        featurecheck: 'check cross',
                      },
                      {
                        id: 5,
                        featurecheck: 'check cross',
                      },
                    ].map(({ featurecheck, id }) => (
                      <div
                        key={id}
                        className="feature-block flex align-items-center justify-content-center">
                        <span
                          className={`flex justify-content-center align-items-center ${featurecheck}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <TrustedBy />
          </div>
          <div className="section features-main" id="features">
            <div className="container">
              <div className="feature-set-single" id="comment-live-website">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/leave-comments-website.jpg"
                      alt="illustration of leaving comment on live website"
                      title="illustration of leaving comment on live website"
                    />
                  </div>
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-50px)' }}>
                    <h2 className="feature-head" style={{ maxWidth: '420px' }}>
                      Leave comments on live websites
                    </h2>
                    <p>
                      Give quick feedback to your team through real-time
                      comments. Keep everyone in the content, design and
                      development teams on the same page. Comment on web pages
                      to share detailed inputs. You could also pin comments on
                      live pixels, give specific creative direction and make
                      work easy for your colleagues.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Comment on Live Website
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="edit-live-website">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div className="feature-txt">
                    <h2 className="feature-head" style={{ maxWidth: '310px' }}>
                      Make edits on live websites
                    </h2>
                    <p>
                      Edit live website and get all the precise changes done
                      before that deadline. Change even the tiniest details like
                      font, spacing, live alignment for your live project.
                      Suggest accurate values for all the iterations and make
                      communication simpler.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Edit your Live Website
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/edit-live-website.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="edit-website-content">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/edit-text.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Edit website content</h2>
                    <p>
                      Make seamless content changes on your website content and
                      see all the changes compiled in real time. Save your time
                      on offline drafts to finalize website content.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Edit Website Content
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="client-commenting">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Client Commenting</h2>
                    <p>
                      Website feedback has got simpler! Your clients can write
                      and even video comment without logging in on ruttl! Simply
                      send the shareability link to them and they just need to
                      type their name after writing the comment. Your clients
                      don’t face the hassles of signing up. They can also tag
                      other project stakeholders to assign any task and get work
                      done.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Check Client Commenting
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/guest-commenting.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="static-image-review">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/review-static-images.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Static Image Review</h2>
                    <p>
                      Make designing and development seamless - upload multiple
                      versions of your chosen images on ruttl. Rename, delete
                      image versions at your ease. Tag your teammates, share
                      feedback with them through comments on images. Resolve
                      these comments by marking them done once the task is
                      completed. Edit, unresolve comments, send them to Trello
                      as well on the go.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Review a Static Image
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="replace-images">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div className="feature-txt">
                    <h2 className="feature-head">
                      Replace images on live website
                    </h2>
                    <p>
                      Quickly replace images on a live website with our fast
                      website annotation tool. Uploading and trying out various
                      images can be a task. With ruttl, you can quickly upload
                      as many images as needed across dimensions and formats
                      within no time - that too on a live website!
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Replace Images on your Website
                    </button>
                  </div>
                  <div className="feature-img">
                    <video
                      src="/assets/img/upload-image.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="inspect-css">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/show-activities.png"
                      alt="ruttl feature to inspect CSS code"
                      title="ruttl feature to inspect CSS code"
                    />
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Inspect CSS</h2>
                    <p>
                      Effortlessly view clean CSS for your website. Avoid
                      wasting your time with any software to edit website
                      content. Instead, go from seeing your live website to
                      editing it in a click with ruttl. Streamline the
                      accessibility issues, redlines and other technical
                      essentials by inspecting CSS with its ease.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Inspecting
                    </button>
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="add-team">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-10px)' }}>
                    <h2 className="feature-head">Add your team</h2>
                    <p>
                      Invite your team members to collaborate on live projects
                      and meet deadlines. Easily notify them about all the
                      changes that you are planning to make on a live project -
                      simply tag them using website comments and give them
                      real-time updates.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Add Team Members
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/add-team.png"
                      alt="illustration of team members in ruttl"
                      title="illustration of team members in ruttl"
                    />
                  </div>
                </div>
              </div>

              <div className="feature-set-single" id="review-mobile-web">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <video
                      src="/assets/img/responsive.mp4"
                      autoPlay
                      muted
                      loop
                      playsInline></video>
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head">Review mobile web</h2>
                    <p>
                      Check and keep your website updated across devices.
                      Quickly switch between desktop and mobile view to make
                      changes as needed. Never miss any details or macro
                      changes. Make your website mobile and web friendly through
                      quick reviews on ruttl.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Start Reviewing
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="versioning">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-13px)' }}>
                    <h2 className="feature-head" style={{ maxWidth: '360px' }}>
                      Record every version on website
                    </h2>
                    <p style={{ maxWidth: '380px' }}>
                      Revisit all the website versions you were working on by
                      recording them. Explore all the changes you have made
                      through the save option. Never miss any updates that were
                      made by your teams. Navigate all the versions in no time
                      and easily collaborate to optimize your website.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Create Website Versions
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/record-version.png"
                      alt="versioning feature of ruttl"
                      title="versioning feature of ruttl"
                    />
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="share-project">
                <div className="flex flex-wrap align-items-center">
                  <div className="feature-img">
                    <img
                      src="/assets/img/share-project-with-clients.png"
                      alt="ruttl project sharing feature"
                      title="ruttl project sharing feature"
                    />
                  </div>
                  <div className="feature-txt">
                    <h2 className="feature-head" style={{ maxWidth: '310px' }}>
                      Share project with clients
                    </h2>
                    <p>
                      With the shareable link, always keep your clients in loop
                      by sharing a unique project link with them, which does not
                      require logging in to ruttl. This way, your clients could
                      view all the progress that you and the team have made. You
                      end up reducing the turnaround time and the feedback loop.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Collaborate on a Project
                    </button>
                  </div>
                </div>
              </div>
              <div className="feature-set-single" id="show-activity">
                <div className="flex flex-wrap align-items-center row-reverse-mob">
                  <div
                    className="feature-txt"
                    style={{ transform: ' translateY(-30px)' }}>
                    <h2 className="feature-head">Show all activities</h2>
                    <p>
                      From the Activities panel, check all your website edits,
                      content changes and comments on web pages. This way, your
                      team never loses track of ongoing activities. Anyone from
                      your team could check all the latest activities done on a
                      website project. Your development team could easily
                      navigate those suggestions and use them for the final
                      codes to create pixel perfect designs.
                    </p>
                    <button
                      type="button"
                      onClick={() => signUp(toggleSignup)}
                      className="button">
                      Check the Activities Panel
                    </button>
                  </div>
                  <div className="feature-img">
                    <img
                      src="/assets/img/show-activity.png"
                      alt="ruttl activities tab"
                      title="ruttl activities tab"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <GetStartedCenter toggleSignup={toggleSignup} />
          <section className="section-gap">
            <div className="container">
              <h2 className="center" style={{ marginBottom: 50 }}>
                Explore our products
              </h2>
              <div className="flex-row justify-content-center">
                {[
                  {
                    id: 0,
                    icon: '/assets/img/website-feedback-icon.svg',
                    heading: 'Website Feedback Tool',
                    text: 'Comment on live websites, report bugs, and make real-time CSS edits for precise feedback',
                    url: '/website-feedback-tool/',
                  },
                  {
                    id: 1,
                    icon: '/assets/img/customer-feedback-icon.svg',
                    heading: 'Customer Feedback',
                    text: 'Chat with your website users in real time & resolve their issues with your team',
                    url: '/customer-feedback-tool/',
                  },
                  {
                    id: 2,
                    icon: '/assets/img/mobile-app-feedback-icon.svg',
                    heading: 'Mobile App Feedback',
                    text: 'Mark issues directly on mobile application, organise feedback using our internal workflow',
                    url: '/mobile-app-feedback/',
                  },
                ].map(({ id, icon, heading, text, url }) => (
                  <div key={id} className="col col-3 col-md-6">
                    <Link to={url} className="icon-text-box">
                      <div className="box-icon">
                        <img src={icon} alt={`${heading} logo`} />
                      </div>

                      <div className="box-desc ">
                        <h3>{heading}</h3>
                        <p>{text}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <div className="app-sumo-testimonials">
            <div className="container">
              <h2 className="heading" style={{ marginBottom: 30 }}>
                People are talking about ruttl
              </h2>

              <div className="app-sumo-testimonials-main">
                <div className="flex flex-wrap">
                  {TestimonialsContent.map(
                    ({
                      img,
                      authorName,
                      authorDesignation,
                      description,
                      id,
                    }) => (
                      <div key={id} className="testimonial-col">
                        <div className="single-testimonial">
                          <div className="flex testimonial-head">
                            <div className="flex align-items-center flex-1">
                              <div className="testimonial-img">
                                <img
                                  src={img}
                                  alt={authorName}
                                  title={authorName}
                                />
                              </div>
                              <div className="flex-1">
                                <h3>{authorName}</h3>
                                <p>{authorDesignation}</p>
                              </div>
                            </div>
                            <div className="app-sumo-rating">
                              {id === 0 ? (
                                <>
                                  <img
                                    src="/assets/img/g2-stars.png"
                                    alt="5 stars in a row"
                                    title="5 stars in a row"
                                    style={{ maxWidth: 120 }}
                                  />
                                  <img
                                    src="/assets/img/g2-logo.png"
                                    alt="g2-logo"
                                    title="g2-logo"
                                    style={{ maxWidth: 40, marginTop: 8 }}
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src="/assets/img/app-sumo-rating.png"
                                    alt="5 appsumo tacos in a row"
                                    title="5 appsumo tacos in a row"
                                    style={{ maxWidth: 120 }}
                                  />
                                  <img
                                    src="/assets/img/app-sumo-full.png"
                                    alt="appsumo logo"
                                    title="appsumo logo"
                                    style={{ maxWidth: 80 }}
                                  />
                                </>
                              )}
                            </div>
                          </div>

                          <div className="testimonial-body">
                            <p>{description}</p>
                          </div>
                        </div>
                      </div>
                    ),
                  )}

                  {TestimonialsContentGoogle.map(
                    ({
                      img,
                      authorName,
                      authorDesignation,
                      description,
                      id,
                    }) => (
                      <div key={id} className="testimonial-col">
                        <div className="single-testimonial">
                          <div className="flex testimonial-head">
                            <div className="flex align-items-center flex-1">
                              <div className="testimonial-img">
                                <img
                                  src={img}
                                  alt={authorName}
                                  title={authorName}
                                />
                              </div>
                              <div className="flex-1">
                                <h3>{authorName}</h3>
                                <p>{authorDesignation}</p>
                              </div>
                            </div>
                            {/* <div className="app-sumo-rating">
                                      <img
                                        src="/assets/img/stars.svg"
                                        alt=""
                                        style={{ maxWidth: 100 }}
                                      />
                                      <img
                                        src="/assets/img/ruttl-logo.svg"
                                        alt=""
                                        style={{ maxWidth: 80, marginTop: 8 }}
                                      />
                                    </div> */}
                          </div>

                          <div className="testimonial-body">
                            <p>{description}</p>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          </div>

          <section className="demo-video">
            <div className="container">
              <h2 className="center">Check the demo video</h2>
              <iframe
                src="https://www.youtube.com/embed/vkx366sknD4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <div className="center">
                <button
                  type="button"
                  onClick={() => signUp(toggleSignup)}
                  target="_blank"
                  rel="noopener norefrrer"
                  className="button"
                  style={{ minWidth: '200px', marginTop: '50px' }}>
                  Start using ruttl now
                </button>
              </div>
            </div>
          </section>
          <NewFaq limitFaq />
        </main>
      </>
    )}
  </Layout>
);

Competitor.propTypes = {
  location: PropTypes.object,
};

export default Competitor;
